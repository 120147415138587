/*=============================================
=            coming soon nineteen            =
=============================================*/

/* content */
.cs-19-content {
    &__subtitle {
        font-family: $montserrat;
        font-size: 30px;
        font-weight: 400;

        color: $red--fifteen;

        @media #{$small-desktop,
        $extra-large-mobile,
        $large-mobile} {
            font-size: 26px;
        }
        @media #{$extra-small-mobile} {
            font-size: 20px;
        }
    }

    &__title {
        font-family: $montserrat;
        font-size: 60px;
        font-weight: 700;
        line-height: 1.3;

        color: $red--fifteen;

        @media #{$large-desktop} {
            font-size: 50px;
        }
        @media #{$small-desktop,
        $extra-large-mobile,
        $large-mobile} {
            font-size: 40px;
        }
        @media #{$small-mobile} {
            font-size: 30px;
        }

        @media #{$extra-small-mobile} {
            font-size: 24px;
        }
    }

    &__text {
        font-family: $montserrat;
        line-height: 1.9;

        width: 570px;
        max-width: 100%;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;

        color: $red--sixteen;
    }
}

/* countdown */
.cs-19-countdown {
    .countdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$extra-large-mobile,
        $large-mobile} {
            flex-wrap: wrap;
            justify-content: space-around;

            margin-bottom: -30px;
        }
    }

    .countdown-item {
        position: relative;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        width: 200px;
        height: 200px;
        margin-right: 50px;

        background-repeat: no-repeat;
        background-position: center center;

        @media #{$extra-large-mobile,
        $large-mobile} {
            margin-right: 0;
            margin-bottom: 30px;
        }

        &:last-child {
            margin-right: 0;
        }

        &__text {
            font-family: $montserrat;
            font-size: 36px;
            font-weight: 700;

            display: flex;
            flex-direction: column;
            justify-content: center;

            width: 110px;
            height: 110px;

            text-align: center;

            color: $red--fifteen;
            border-radius: 50%;
            box-shadow: 0 10px 15px rgba($red--eighteen, 0.16);

            span {
                font-family: $montserrat;
                font-size: 16px;
                font-weight: 400;

                display: block;

                margin-top: 15px;

                text-transform: uppercase;
            }
        }
    }

    .countdown-svg {
        position: absolute;

        width: 180px;
        height: 180px;
        path {
            transform: translate(20px, 20px);
        }
    }
}

/* subscription */
.cs-19-subscription-wrapper {
    .mc-newsletter-form {
        input[type="email"] {
            font-family: $roboto;
            font-size: 16px;
            font-weight: 400;

            width: 450px;
            max-width: 100%;
            margin-right: 25px;
            padding: 25px 30px;

            color: $red--ten;
            border: 1px solid $red--seventeen;
            border-radius: 10px;
            background-color: rgba($white, 0.5);

            @media #{$small-desktop,
            $extra-large-mobile} {
                width: 300px;
                margin-right: 5px;
                padding: 20px 30px;
            }

            @media #{$large-mobile} {
                margin-right: 0;
                margin-bottom: 15px;
                padding: 20px 30px;
            }
        }

        button {
            font-family: $roboto;
            font-size: 18px;
            font-weight: 500;

            padding: 25px 65px;

            color: $white;
            border: none;
            border-radius: 10px;
            background: none;
            background-color: $red--two;

            @media #{$small-desktop,
            $extra-large-mobile,
            $large-mobile} {
                font-size: 16px;

                padding: 22px 40px;
            }

            &:hover {
                background-color: darken($color: $red--two, $amount: 10);
            }
        }
        ::placeholder {
            opacity: 1;

/* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $red--ten;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $red--ten;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $red--ten;
        }
    }

    .mailchimp-alerts {
        font-family: $montserrat;
        line-height: 1.3;

        color: $red--ten;
        a {
            &:hover {
                color: $red--two;
            }
        }
    }
}

/* social icon*/
.cs-19-social-icons {
    & > li {
        margin-right: 20px;
        & > a {
            position: relative;

            width: 50px;
            height: 50px;

            border: 1px solid $red--seventeen;
            border-radius: 50%;
            background-color: transparent;
            svg {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);
                * {
                    transition: $transition--default;

                    fill: $red--seventeen;
                }
            }

            &:hover {
                border-color: $red--two;
                background-color: $red--two;
                svg {
                    * {
                        fill: $white;
                    }
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }

    @media #{$large-mobile} {
        margin-top: 15px;
    }
}

/* copyright text */
.cs-19-copyright {
    font-family: $roboto;

    color: $red--seventeen;

    @media #{$large-mobile} {
        text-align: center;
    }

    a {
        &:hover {
            color: $red--two;
        }
    }
}

/*=====  End of coming soon nineteen  ======*/
