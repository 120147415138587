/*=============================================
=            Default            =
=============================================*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body,
#root,
.site-wrapper {
  height: 100%;
}

body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;

  margin: 0;

  color: $body-text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $heading-font-weight;
  line-height: 1;

  margin-top: 0;
  margin-bottom: 0;

  color: $theme-color--heading;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;

  display: inline-block;

  cursor: pointer;
  transition: $transition--default;
  text-decoration: none;

  color: inherit;
}
*:focus {
  outline: none;
}
a:focus {
  text-decoration: none;

  color: inherit;
  outline: none;
}
a:hover {
  text-decoration: none;

  color: $theme-color--default;
}

input[type="submit"] {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;

  list-style: outside none none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent !important;
} /* FF 4-18 */
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent !important;
} /* FF 19+ */
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent !important;
} /* IE 10+ */

/*=====  End of Default  ======*/
